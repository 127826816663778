/* eslint-disable import/prefer-default-export */

export const menuItems = [
  'backoffice',
  'settings',
  'bo-contact',
  // 'availability',
  // 'analytics',
  // 'history',
  'data-manager',
  'owner',
  'backoffice-members',
  'backoffice-members-details',
  'backoffice-organizations',
  'backoffice-organizations-details',
  'roles',
  'role-details',
  'roles-community',
  'staff-roles',
  'staff-role-details',
  'staff',
  'classifiers',
  'classifier-details',
  'custom-fields',
  'custom-fields-details',
  'feedback',
  'feedback-details',
  'forms-bo',
  'forms-bo-details',
  'faqs-details',
  'backoffice-legal',
  'addons',
  'addon',
  // 'integrations',
  // 'integration',
  'plans',
  'types',
  'statuses',
  'appstore',
  // 'backoffice-referrals',
  'backoffice-onboarding-form',
  'templates-bo',
  'discounts-bo',
  // 'developers-center',
  'payments',
  'invoices',
  'shipments',
  'transactions',
  'sepa',
  'auth',
  // 'coordination-center',
  // 'subscriptions',
];

export const backofficeDetailsRoutes = [
  'backoffice-members-details',
  'backoffice-organizations-details',
  'role-details',
  'classifier-details',
  'forms-bo-details',
  'custom-fields-details',
  'feedback-details',
  'faqs-details',
  'addons',
  'addon',
  'integrations',
  'integration',
];
